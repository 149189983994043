'use client'

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { SignerOptions } from '@cosmos-kit/core'
import { kleoTheme } from '../../config'
import { ChainProvider } from '@cosmos-kit/react'
import { assets, chains } from 'chain-registry'
import { AssetList } from '@chain-registry/types'
import { wallets as keplrWallets } from '@cosmos-kit/keplr'
import { wallets as cosmostationWallets } from '@cosmos-kit/cosmostation'
import { wallets as leapWallets } from '@cosmos-kit/leap'
import '@interchain-ui/react/globalStyles'
import '@interchain-ui/react/styles'
import React from 'react'
import { QueryClient } from '@tanstack/query-core'
import { QueryClientProvider } from '@tanstack/react-query'
import { GasPrice } from '@cosmjs/stargate'

// @ts-ignore
BigInt.prototype['toJSON'] = function () {
  return this.toString()
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
})

export function Providers({ children }: { children: React.ReactNode }) {
  const signerOptions: SignerOptions = {
    // @ts-ignore
    signingStargate: chain => {
      let gasPrice
      try {
        // TODO fix type error
        // @ts-ignore
        const feeToken = chain.fees?.fee_tokens[0]
        const fee = `${feeToken?.average_gas_price || 0.025}${feeToken?.denom}`
        gasPrice = GasPrice.fromString(fee)
      } catch (error) {
        gasPrice = GasPrice.fromString('0.025uosmo')
      }
      return { gasPrice }
    },
  }

  return (
    <>
      <ChakraProvider theme={kleoTheme}>
        <ChainProvider
          chains={chains}
          assetLists={assets as AssetList[]}
          wallets={[...keplrWallets, ...leapWallets, ...cosmostationWallets]}
          walletConnectOptions={{
            signClient: {
              projectId: '527266e8bac8b6420f2fe25ae52baccc',
              relayUrl: 'wss://relay.walletconnect.org',
              metadata: {
                name: 'KLEO Web App',
                description: 'Kleomedes web app',
                url: 'https://kleomed.es/',
                icons: [],
              },
            },
          }}
          signerOptions={signerOptions}
        >
          <QueryClientProvider client={queryClient}>
            {/* 👇 Here's the script */}
            <ColorModeScript initialColorMode={kleoTheme.initialColorMode} />
            {children}
          </QueryClientProvider>
        </ChainProvider>
      </ChakraProvider>
    </>
  )
}
