import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/app/(multichain)/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/montserrat/900.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/montserrat/700.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/montserrat/600.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/montserrat/500.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/montserrat/400.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/montserrat/300.css");
